<div class="container" *ngIf="mediaUploadValidation">
	<div class="row">
		<div class="col-lg-12 logo-wap text-center">
			<img width="100px" src="../../assets/img/loading.gif" />
			<h4>Validating <br> Media Upload URL...</h4>
		</div>
	</div>
</div>

<div class="container" *ngIf="mediaUploadPage">
	<div class="row" id="mediaContainer">
		<div class="col-lg-12 logo-wap text-center">
			<img width="100px" src="{{ mediaHost + mediaUploadUrlAccess.organization.logo }}" id="orglogo" />
			<h4>MEDIA CENTER</h4>
			<p class="lead-small">Upload or take media of the incident.</p>
		</div>
		<div class="col-lg-12 text-center">
			<div class="uplaod-wapper text-center">
				<div class="upload_box">
					<div *ngIf="mediaFiles.length == 0">
						<input type="file" accept="image/*,video/*" name="input-file-preview"
							(change)="handleFileInput($event.target.files)" multiple>
						<div class="upload_text_icon">
							<img width="34" src="../../assets/img/uploading.png" />
							<br /> <br />
							UPLOAD MEDIA
						</div>
					</div>
					<div *ngIf="mediaFiles.length > 0">
						<button class="img-preview-btn" (click)="showUploadedFiles()">
							<img class="image_preview_icon" width="26" src="../../assets/img/rectangle.png">
							<img class="img-preview" width="130" height="130" *ngIf="mediaFiles[0].type == 1"
								src="{{mediaFiles[0].src}}" />
							<video class="img-preview" width="130" height="130" *ngIf="mediaFiles[0].type == 2"
								src="{{mediaFiles[0].src}}#t=0.1"> </video>
						</button>
					</div>
				</div>
				<div class="upload_box">
					<input type="file" accept="image/*,video/*" capture="environment"
						(change)="handleFileInput($event.target.files)" multiple />
					<div class="upload_text_icon">
						<img width="34" src="../../assets/img/camara.png" />
						<br /> <br />
						TAKE PHOTO/VIDEO
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-12 text-area text-center" *ngIf="mediaFiles.length > 0">
			<textarea class="form-control" placeholder="Add any description if context is needed…" rows="4"
				id="mediaText" name="mediaText" [(ngModel)]="mediaText" (focus)="mediaTextEvent = true"
				(blur)="mediaTextEvent = false" maxlength="480">{{mediaText}}</textarea>
			<a class="btn btn-primary custom_btn mr-top" href="javascript:void(0);" role="button"
				(click)="saveUploadedMedia()"> <img width="18" src="../../assets/img/send.png">&nbsp; SEND MEDIA </a>
		</div>
	</div>
	<div class="row">
		<!-- Site footer -->
		<footer class="footer"
			[ngClass]="{'footer_fixed': innerHeight > 500, 'footer_fixed_removed': innerHeight <= 500}"
			*ngIf="mediaTextEvent == false">
			<p class="mr-bottom-10"> <img width="200" src="../../assets/img/footer-logo.png" /> </p>
			<p> <a href="https://www.saferwatchapp.com/privacy-policy/" target="_blank"> Privacy Policy </a>
				<p>
		</footer>
	</div>
</div>

<div class="container" *ngIf="mediaUploadDialog">
	<div class="row">
		<div class="col-lg-12 logo-wap manage-uplaod-wapper text-center">
			<p class="lead"> <span class="pull-left back-arrow" (click)="hideUploadedFiles()"> </span>
				<!--<img  width="12" src=""- />--> MANAGE UPLOADS</p>
			<p class="lead lead-small">Upload up to 6 photos or videos </p>
		</div>

		<div class="col-lg-12 pd-0">
			<div class="col-lg-4 text-center"> </div>
			<div class="col-lg-4 privew-wapper text-center">
				<img width="100%" *ngIf="selectedMedia.type == 1" src="{{selectedMedia.src}}">
				<video width="100%" *ngIf="selectedMedia.type == 2" src="{{selectedMedia.src}}#t=0.1" controls> </video>
				<span class="close_icon" (click)="removeFile()">  </span>
			</div>
			<div class="col-lg-4 text-center"> </div>
		</div>

		<div class="col-lg-12 pd-0">
			<div class="col-lg-4 text-center"> </div>
			<div class="col-lg-4 media-carousel-container">
				<span *ngFor="let mediaFile of mediaFiles">
					<span class="privew-img" [ngClass]="{active: isActive(mediaFile)}">
						<img src="{{mediaFile.src}}" *ngIf="mediaFile.type == 1" width="50" height="50"
							(click)="loadPreview(mediaFile)" />
						<!--<img src="../../assets/img/play_bg.png" *ngIf="mediaFile.type == 2" width="50" height="50"
							(click)="loadPreview(mediaFile)" />-->
						<video src="{{mediaFile.src}}#t=0.1" *ngIf="mediaFile.type == 2" width="50" height="50"
							(click)="loadPreview(mediaFile)"> </video> 
					</span>
				</span>
				<span *ngIf="mediaFiles.length < 6">
					<span class="privew-img uplaod-media">
						<input type="file" accept="image/*,video/*" name="input-file-preview"
							(change)="handleFileInput($event.target.files)" multiple> <img
							src="../../assets/img/camera_white.png">
					</span>
				</span>
			</div>
			<div class="col-lg-4 text-center"> </div>
		</div>

		<div class="col-lg-12 logo-wap manage-uplaod-wapper text-center">
			<a class="btn btn-primary custom_btn" href="javascript:void(0)" role="button" (click)="hideUploadedFiles()">
				CONTINUE </a>
		</div>
	</div>
</div>

<div class="container container-thanku" *ngIf="mediaThankYouPage">
	<div class="row">
		<div class="col-lg-12 logo-wap thanku-wapper text-center">
			<a href="https://SaferWatchApp.com"><img width="290" src="../../assets/img/saferwatch-white-logo.png" /></a>
			<h4> <b> Report ID: {{ mediaUploadUrlAccess.report_id }} </b> </h4>
			<h4 class="mr-top">THANK YOU </h4>
			<p class="lead lead-large">
				You have successfully uploaded and sent media to <br>{{ mediaUploadUrlAccess.organization.name }}
			</p>
		</div>

		<div class="col-lg-12 ">
			<div class="row">
				<div class="col-lg-4 text-center"> </div>
				<div class="col-lg-4 playstore-view text-center">
					<p class="lead">
						Download the SaferWatch App <br>for More Reporting & Safety Features
					</p>
					<div class="store_btn">
						<a href="https://itunes.apple.com/us/app/saferwatch/id1409514604?mt=8">
							<img width="251" src="../../assets/img/app-store.png" />
						</a>
					</div>
					<div class="store_btn">
						<a href="https://play.google.com/store/apps/details?id=com.ad.saferwatch">
							<img width="251" src="../../assets/img/google-play.png" />
						</a>
					</div>
				</div>
				<div class="col-lg-4 text-center"> </div>
			</div>
		</div>
		<div class="col-lg-12 pd-0 text-center name-text"> <a href="https://SaferWatchApp.com">SaferWatchApp.com</a>
		</div>
	</div>
</div>

<div class="overlay" *ngIf="mediaLoader">
	<div class="loader_text_icon">
	<div class="spinner"></div>
	<br />
	<h4>Loading...</h4>
</div>
</div>
