import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { MediaUpload, S3Access, Media, APIResponse } from '../media-upload';
import { Subscription, interval } from 'rxjs';
import { environment } from '../../environments/environment'
import * as $ from 'jquery';
 
@Component({
	selector: 'sw-media-upload',
	templateUrl: './media-upload.component.html',
	styleUrls: ['./media-upload.component.css']
})

export class MediaUploadComponent implements OnInit {

	mediaUploadValidation: boolean = true;
	mediaUploadPage: boolean = false;
	mediaUploadDialog: boolean = false;
	mediaThankYouPage: boolean = false;
	mediaLoader: boolean = false;

	media_url_id: number;
	mediaUploadUrlAccess: S3Access;
	authenticateAppFilesSubscription: Subscription;
	mediaHost = environment.MEDIA_HOST
	mediaFiles: Media[] = [];
	selectedMedia: Media;
	mediaText: string = "";
	mediaTextEvent: boolean = false;
	innerHeight: any = window.innerHeight;

	constructor(private route: ActivatedRoute, private router: Router, private apiService: ApiService) {}

	ngOnInit(): void {
		this.media_url_id = +this.route.snapshot.paramMap.get('mediaUploadId');
		if (this.media_url_id != undefined) {
			this.getMediaUploadToken()
		}
	}

	ngOnDestroy() {
		if(this.authenticateAppFilesSubscription){
			this.authenticateAppFilesSubscription.unsubscribe();
		}		
	}

	getMediaUploadToken() {
		this.apiService.getAwsAccessToken(this.media_url_id, null).subscribe(resp => {
			this.mediaUploadUrlAccess = resp
			if (this.mediaUploadUrlAccess.status_code == 200) {
				// 15 mins interval for app s3 upload logic
				this.authenticateAppFilesSubscription = interval(14 * 60 * 1000).subscribe(x => {
					this.apiService.getAwsAccessToken(this.media_url_id, null).subscribe(res => {
						this.mediaUploadUrlAccess = res
					});
				});
				// setup media upload page
				this.mediaUploadValidation = false;
				this.mediaUploadPage = true;
			} else {
				// go to expire page
				//this.authenticateAppFilesSubscription.unsubscribe()
				this.router.navigate(['/expired']);
			}
		});
	}
	
	async handleFileInput(files: FileList) {
		const that = this;
		const timeStamp = Math.floor(new Date().getTime()).toString()
		let maxFiles = 6;
		if (files.length + this.mediaFiles.length > maxFiles) {
			alert("Max 6 files can be uploaded.");
			maxFiles = maxFiles - this.mediaFiles.length;
		}
		for (let index = 0; index < maxFiles; index++) {
			const file = files[index];
			console.log(file)
			const fileName = timeStamp + '-' + file.name;
			const fileExt = file.type.split('/')[0]
			// file extension check
			if (!(fileExt == "image" || fileExt == "video")) { alert("Only image and video files can be uploaded."); continue; }
			this.mediaLoader = true;
			let uploadResponse = await this.apiService.uploadFilesOnServer(this.mediaUploadUrlAccess, file, fileName)
			this.mediaLoader = false;
			if (uploadResponse) {
				if (fileExt == "image") {
					const reader = new FileReader();
					reader.onload = function (e) {
						let image = new Image();
						image.src = e.target.result.toString();
						image.onload = function () {
							that.mediaFiles.push({
								's3key': fileName,
								'width': image.width,
								'height': image.height,
								'type': 1,
								'src': image.src,
								'mediaUrl': environment.MEDIA_HOST + fileName,
								'filename': fileName,
								'in_transcoding': false
							});
							that.mediaUploadComplete(files)
						};
					};
					reader.readAsDataURL(file);
				} else if (fileExt == "video") {
					//#region fileconvert
					// that.apiService.fileConvert(fileName).subscribe(resp => {
					// 	console.log("convert:resp", resp)
					// }, err => {
					// 	console.log("convert:err", err)
					// })
					//#endregion
					that.mediaFiles.push({
						's3key': fileName,
						'width': 720,
						'height': 1080,
						'type': 2,
						'src': environment.MEDIA_HOST + fileName,
						'mediaUrl': environment.MEDIA_HOST + fileName,
						'filename': fileName,
						'in_transcoding': true
					});
					that.mediaUploadComplete(files)
				}
			}
		}
	}

	mediaUploadComplete(files: FileList) {
		if (this.mediaFiles.length > 0) {
			this.showUploadedFiles()
		}
	}

	showUploadedFiles() {
		this.selectedMedia = this.mediaFiles[0]
		this.mediaUploadPage = false;
		this.mediaUploadDialog = true;
	}

	hideUploadedFiles() {
		this.mediaUploadPage = true;
		this.mediaUploadDialog = false;
		setTimeout(() => {
			if (this.mediaFiles.length > 0) {
				if (window.innerHeight > 600) {
					this.innerHeight = window.innerHeight;
				} else {
					this.innerHeight = $('#mediaContainer').innerHeight()
				}
			} else {
				this.innerHeight = window.innerHeight;
			}
		}, 500);
	}

	loadPreview(mediaFile: Media) {
		this.selectedMedia = mediaFile
	}

	removeFile() {
		let isDelete = confirm("Are you sure you want to delete this file ?");
		if (isDelete == true) {
			this.mediaFiles.splice(this.mediaFiles.indexOf(this.selectedMedia), 1);
			if (this.mediaFiles.length > 0) { 
				this.selectedMedia = this.mediaFiles[0]
			} else {
				// this.selectedMedia = new Media; 
				this.hideUploadedFiles()
			}
		}
	}

	saveUploadedMedia() {
		let confirmSend = confirm("Are you sure you want to send media ?")
		if (confirmSend == true) {
			this.mediaLoader = true;
			this.mediaFiles.forEach(m => m.src = "");
			let mediaUpload = new MediaUpload();
			mediaUpload.media_url_id = this.media_url_id;
			mediaUpload.user_agent = "";
			mediaUpload.media = this.mediaFiles;
			mediaUpload.media_text = this.mediaText;
			mediaUpload.live_sms_chat_id = this.mediaUploadUrlAccess.live_sms_chat_id
			mediaUpload.report_id = this.mediaUploadUrlAccess.report_id
			mediaUpload.organization = this.mediaUploadUrlAccess.organization
			this.apiService.saveMediaUploads(mediaUpload).subscribe(resp => {
				this.authenticateAppFilesSubscription.unsubscribe()
				let apiResponse: APIResponse = resp;
				this.mediaLoader = false;
				if (apiResponse.status_code == 200) {
					this.mediaThankYouPage = true;
					this.mediaUploadPage = false;
					document.body.classList.add('thanku-body');
				} else {
					this.router.navigate(['/expired']);
				}
			}, err => {
				console.error(err)
			})
		}
	}

	isActive(mediaFile) {
		return this.selectedMedia === mediaFile;
	};  

}
