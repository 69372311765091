import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { ApiService } from './api.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MediaUploadComponent } from './media-upload/media-upload.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@NgModule({
	declarations: [
		AppComponent,
		MediaUploadComponent,
		NotFoundComponent,
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		AppRoutingModule,
		FormsModule,
	],
	providers: [HttpClientModule, ApiService, {
		provide: 'externalUrlRedirectResolver',
		useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
			window.location.href = (route.data as any).externalUrl;
		}
	}],
	bootstrap: [AppComponent]
})
export class AppModule {}
