// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	API_HOST: 'https://wgry439htj.execute-api.us-east-1.amazonaws.com/latest/',
	MEDIA_HOST: 'https://d1ync0e6o55dux.cloudfront.net/',
	PUBLIC_TOKEN: '6145e181850faeb6cdae3d5419c6e17c',
	MEDIA_UPLOAD: 'authenticateappforfiles',
	MEDIA_SAVE: 'savemediauploadurlfiles',
	MEDIA_CONVERT: 'saferwatchfileimport',
	MEDIA_CONVERSION_SERVER: 'https://alerts.saferwatchapp.com/media-converter'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
