export class Orgnization {
    id: string;
    logo: string;
    name: string;
}

export class APIResponse {
    status_code: number;
    live_sms_chat_id: string;
    report_id: string;
    organization: Orgnization;
}

export class MediaUpload extends APIResponse {
    media_url_id: number;
    user_agent: string;
    media: Media[];
    media_text: string;
}

export class Media {
    s3key: string;
    width: number;
    height: number;
    type: number;
    src: string;
    mediaUrl: string;
    filename: string;
    in_transcoding: boolean;
}

export class S3Access extends APIResponse {
    token: string;
    identity_id: string;
    identity_pool_id: string;
    bucket: string;
    create_time: number;
}