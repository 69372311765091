import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';  
import { HttpHeaders } from '@angular/common/http';  
import { Observable } from 'rxjs';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { MediaUpload, Media, S3Access, APIResponse } from './media-upload';
import { environment } from '../environments/environment';

@Injectable({
	providedIn: 'root'
})

export class ApiService {
	apiOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + environment.PUBLIC_TOKEN
		})
	}

	constructor(private http: HttpClient) {}

	getAwsAccessToken(mediaUploadId: number, userAgent: string): Observable < S3Access > {
		return this.http.post < S3Access > (`${environment.API_HOST + environment.MEDIA_UPLOAD}`, {
			media_url_id: mediaUploadId,
			user_agent: userAgent
		}, this.apiOptions);
	}

	saveMediaUploads(mediaUpload: MediaUpload): Observable < APIResponse > {
		return this.http.post < APIResponse > (`${environment.API_HOST + environment.MEDIA_SAVE}`, mediaUpload, this.apiOptions);
	}

	fileConvert(mediaFile: string): Observable < APIResponse > {
		return this.http.post < APIResponse > (`${environment.API_HOST + environment.MEDIA_CONVERT}`, {
			media_key: mediaFile
		}, this.apiOptions);
	}

	fileUploadAndConvert(mediaUploadUrlAccess: S3Access, file: File, fileKey: string) {
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('file_key', fileKey);
			formData.append('identity_pool_id', mediaUploadUrlAccess.identity_pool_id);
			formData.append('identity_id', mediaUploadUrlAccess.identity_id);
			formData.append('token', mediaUploadUrlAccess.token);
			formData.append('bucket', mediaUploadUrlAccess.bucket);
			formData.append('region', mediaUploadUrlAccess.identity_id.split(':', 1)[0]);
			this.http.post < any > (`${environment.MEDIA_CONVERSION_SERVER}`, formData, {
				headers: new HttpHeaders({
					'Authorization': 'saferupload-media-converter'
				})
			}).subscribe(resp => {
				console.log("fileConvertLocal", resp)
				if(resp && resp.status_code == 200) {
					resolve(resp)
				} else {
					resolve(false)
				}
			}, err => {
				console.log("fileConvertLocal:err", err)
				resolve(false)
			})
		})
	}

	uploadFilesOnServer(mediaUploadUrlAccess: S3Access, file: File, fileKey: string) {
		// update aws credentials
		AWS.config.update({
			credentials: new AWS.CognitoIdentityCredentials({
				IdentityPoolId: mediaUploadUrlAccess.identity_pool_id,
				IdentityId: mediaUploadUrlAccess.identity_id,
				Logins: {
					'cognito-identity.amazonaws.com': mediaUploadUrlAccess.token
				}
			}),
			region: mediaUploadUrlAccess.identity_id.split(':', 1)[0],
			useAccelerateEndpoint: true
		});
		// set default timeout to 5 min
		AWS.config.httpOptions = {
			timeout: 300000
		};
		AWS.config.httpOptions.timeout = 300000;
		// define S3 Upload
		const s3 = new S3({
			apiVersion: '2006-03-01'
		});
		return new Promise((res, rej) => {
			s3.upload({
				Bucket: mediaUploadUrlAccess.bucket,
				Key: fileKey,
				Body: file,
				ContentType: file.type
			}, function (err, data) {
				// console.log(err, data)
				if(err) res(false)
				else res(true)
			});
		})
		//for upload progress   
		/*bucket.upload(params).on('httpUploadProgress', function (evt) {
			console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
		}).send(function (err, data) {
			if (err) {
				console.log('There was an error uploading your file: ', err);
				return false;
			}
			console.log('Successfully uploaded file.', data);
			return true;
		});*/
	}
}
