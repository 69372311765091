<div class="container container-expire">
	<div class="row">
		<div class="col-lg-12 logo-wap thanku-wapper expire-wapper text-center">
			<a href="https://saferwatchapp.com"><img width="200" src="../../assets/img/saferwatch-white-logo.png" /></a>
			<h4 class="mr-top">Invalid/Expired Link </h4>
			<p class="lead lead-large">
		This link has expired. Please request a new link if needed by texting the same number you received the link from.
			</p>
		</div>
	</div> <!-- /row -->
</div> <!-- /container -->
