import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MediaUploadComponent } from './media-upload/media-upload.component';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [{
	path: "expired",
	component: NotFoundComponent
}, {
	path: ":mediaUploadId",
	pathMatch: "full",
	component: MediaUploadComponent
}, {
	path: "**",
	component: MediaUploadComponent,
	resolve: {
		url: 'externalUrlRedirectResolver'
	},
	data: {
		externalUrl: 'https://getsaferwatch.com/'
	}
}];

@NgModule({
	declarations: [],
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})

export class AppRoutingModule {}